import MemberObject from '@/data/videotherapie/MemberObject';
import DisconnectMessage from '@/data/videotherapie/messages/outgoing/DisconnectMessage';
import EndMeldungByTherapeutMessage from '@/data/videotherapie/messages/outgoing/EndMeldungByTherapeutMessage';
import MeldungMessage from '@/data/videotherapie/messages/outgoing/MeldungMessage';
import MuteMessage from '@/data/videotherapie/messages/outgoing/MuteMessage';
import UserMutedMessage from '@/data/videotherapie/messages/outgoing/UserMutedMessage';
import WebsocketMessageOutgoing from '@/data/videotherapie/messages/outgoing/WebsocketMessageOutgoing';
import StreamObject from '@/data/videotherapie/StreamObject';
import Stomp from "webstomp-client";
import api from "../../config/api";
import JoinConferenceMessage from "../../data/videotherapie/messages/outgoing/JoinConferenceMessage";
import PingMessage from "../../data/videotherapie/messages/outgoing/PingMessage";
import PublishSdpResponseMessage from "../../data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import PublishStreamMessage from "../../data/videotherapie/messages/outgoing/PublishStreamMessage";
import SubscribeSdpResponseMessage from "../../data/videotherapie/messages/outgoing/SubscribeSdpResponseMessage";
import SubscribeStreamMessage from "../../data/videotherapie/messages/outgoing/SubscribeStreamMessage";
import { iceConfiguration } from './iceConfiguration';



export default {
	connect(context: any) {
		if (!context.errorCount) context.errorCount = 0;
		context.stompClient = Stomp.client(api.getWebsocketBackendURL());
		if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Baue Verbindung zum Server auf";
		context.stompClient.connect(
			{},
			() => {
				context.sessionId = context.$store.state.authentication.userId
				// Hier wird die UserId als SessionId genutzt.
				// Das ist vielleicht suboptimal, da hier ein Kommentar dazu stand - K
				console.log("SessionId: ", context.sessionId)
				context.stompClient.subscribe(
					"/topic/private/" + context.sessionId,
					(message: any) => {
						if (context.subscribed !== null) context.subscribed = true;
						context.handleMessage(JSON.parse(message.body));
					},
					{ bearer: context.$store.state.authentication.token }
				);
				context.connected = true;
				context.errorCount = 0;
				if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Baue Kommunikationkanal auf";
				this.startSession(context)
			},
			(error: Error) => {
				context.errorCount++;
				console.log("Websocket-Fehler");
				console.log(error);
				console.log("Error count: " + context.errorCount);
				context.connected = false;
				if (context.errorCount < 5) {
					this.reconnect(context);
				}
			}
		);
		context.conferenceDialog = true;
		return "<br>Baue Kommunikationkanal auf";
	},
	disconnect(context: any, forwardPageAndShowMessage?: boolean) {
        this.sendMessage(context, new DisconnectMessage(""))  
		
		if (context.stompClient) {
			try{
				context.stompClient.deactivate()
			}
			catch(e){
				console.log(e)
			}
			
			context.stompClient.disconnect();
		}
		Object.keys(context.streams).forEach(key => {
			let value = context.streams[key];
			value.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.streams = [];
		Object.keys(context.localStreams).forEach(key => {
			let value = context.localStreams[key];
			value.mediaStream.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.localStreams = {};
		Object.keys(context.tracks).forEach(key => {
			context.tracks[key].stop();
		});
		context.tracks = [];
		
		if(context.localPc){
			(context.localPc as RTCPeerConnection).close();
			console.debug((context.localPc as RTCPeerConnection).signalingState)
		}
		context.otherStream = null;
		context.ownStream = null;
		if (context.connected) {
			context.connected = false;
			if(forwardPageAndShowMessage){
				if (context.conferenceId === "loopback") {
					var headline = "Verbindungstest beendet";
					var text = "Der Verbindungstest wurde beendet. Der Zugriff auf Ihre Kamera und Ihr Mikrofon wurde wieder deaktiviert.";
				} else {
					var headline = "Termin beendet";
					var text = "Die Verbindung wurde beendet. Der Zugriff auf Ihre Kamera und Ihr Mikrofon wurde wieder deaktiviert.";
				}
				context.$store.state.error = {
					isVisible: true,
					headline: headline,
					text: text,
					okAction: function () { context.$router.push(context.afterDisconnectUrl) }
				}
			}
		}
		this.clearPingTimer(context);
	},
	reconnect(context: any) {
		//disconnect with no info

		if (context.stompClient) {
			context.stompClient.disconnect();
		}
		Object.keys(context.streams).forEach(key => {
			let value = context.streams[key];
			value.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.streams = [];
		Object.keys(context.localStreams).forEach(key => {
			let value = context.localStreams[key];
			value.mediaStream.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.localStreams = {};
		console.log(context.tracks)
		Object.keys(context.tracks).forEach(key => {
			context.tracks[key].stop();
		});
		context.tracks = [];
		context.localPc = null;
		context.otherStream = null;
		context.ownStream = null;
		if (context.connected) {
			context.connected = false;
		}
		//connect again
		this.connect(context);
	},
	disconnectSilent(context: any) {

		if (context.stompClient) {
			context.stompClient.disconnect();
		}
		Object.keys(context.streams).forEach(key => {
			let value = context.streams[key];
			value.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.streams = [];
		Object.keys(context.localStreams).forEach(key => {
			let value = context.localStreams[key];
			value.mediaStream.getTracks().forEach(function (track: any) {
				track.stop();
			});
		});
		context.localStreams = {};
		console.log(context.tracks)
		Object.keys(context.tracks).forEach(key => {
			context.tracks[key].stop();
		});
		context.tracks = [];
		context.localPc = null;
		context.otherStream = null;
		context.ownStream = null;
		if (context.connected) {
			context.connected = false;
		}
		this.clearPingTimer(context);
	},
	startSession(context: any) {
		if (context.testStarted !== null) context.testStarted = true;
		context.conferenceDialog = false;
		if (!context.stompClient) {
			context.connect();
		} else {
			if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Starte Sitzung zum Verbindungstest";
			//DEBUGGING: This join may not work as intended
			if (context.conferenceId !== null && context.conferenceId !== undefined) {
				this.sendMessage(context, new JoinConferenceMessage("", context.conferenceId));
				context.joining = true;
				context.pingTimeoutId = window.setInterval(function (scope: any, context: any) {
					scope.sendPing(context);
				}, 120000, this, context);
			}
		}
	},
	clearPingTimer(context: any) {
		if (context.pingTimeoutId) {
			console.log('clearIntervalId ' + context.pingTimeoutId);
			window.clearInterval(context.pingTimeoutId);
		}
	},
	sendMessage(context: any, msg: WebsocketMessageOutgoing) {
		msg.bearer = context.$store.state.authentication.token;
		context.stompClient.send("/app/sendOnly", JSON.stringify(msg), {});
	},
	publishStream(context: any) {
		const that: any = this;
		if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br> Zugriff auf Kamera";
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: true })
			.then(function getUserMediaSuccess(stream) {
				const cameraStream = {
					name: "camera",
					mediaStream: stream,
				};
				context.localStreams[cameraStream.name] = cameraStream;
				context.ownStream = cameraStream.mediaStream;
				if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Füge eigenen Stream in Seite ein";
				if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Sende eigenes Video";
				that.sendMessage(context,
					new PublishStreamMessage("",cameraStream.name, true, true)
				);
			})
			.catch(function getUserMediaError(error) {
				//console.error(error);
				if (context.testStep == "" || context.testStep===undefined || context.testStep===null){
					const mainStore = context.$store
					console.debug("Fehler in getUserMedia: ",error)
					console.log(mainStore)
					mainStore.state.error = {
						isVisible: true,
						headline: "Fehler beim Zugriff auf die Kamera",
						text: 'Der Zugriff auf die Kamera ist nicht möglich. \n\nBitte schließen Sie alle Apps, die die Kamera nutzen. Starten Sie dann den Termin erneut.',
						okAction: () => {
						window.history.back()
						}
					}

				}
			});
	},
	handlePublishSdpRequest(context: any, sdp: string, stream: StreamObject) {
		// the backend sent an SDP offer for publishing our local stream
		const that: any = this;
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: true })
			.then(gumStream => {
				context.localPc = new RTCPeerConnection(iceConfiguration);
				console.log(context.localPc);
				context.localPc
					.setRemoteDescription({ type: "offer", sdp: sdp })
					.then(function setRemoteOk() {
						for (const track of gumStream.getTracks()) {
							context.localPc.addTrack(track);
							context.tracks.push(track);
						}
						context.localPc
							.createAnswer()
							.then(function createAnswerOk(description: any) {
								return context.localPc.setLocalDescription(description);
							})
							.then(function setLocalOk(description: any) {
								that.sendMessage(context,
									new PublishSdpResponseMessage(
										"",
										context.localPc.currentLocalDescription.sdp,
										stream.rxRtpEndpointId,
										stream.streamUuid
									)
								);
							});
					});
			});
	},
	handleSubscribeSdpRequest(
		context: any,
		sdp: string,
		stream: StreamObject,
		txRtpEndpointId: string
	) {
		// the backend sent an SDP offer for receiving a remote stream
		const pc = new RTCPeerConnection(iceConfiguration);
		const that = this;
		pc.ontrack = function (event) {
			for (const streamObject of context.otherStreams) {
				if (
					streamObject.stream !== null &&
					streamObject.stream.streamUuid == stream.streamUuid
				) {
					console.log("setting video of streamobject")
					console.log("index of streamobject:" + context.otherStreams.indexOf(streamObject))
					streamObject.video = event.streams[0];
					context.streams.push(event.streams[0])
					streamObject.show = false;
					context.showVideo = false;
					setTimeout(() => { streamObject.show = true; context.showVideo = true }, 20);
					break;
				}
			}
		};
		pc.setRemoteDescription(
			new RTCSessionDescription({ type: "offer", sdp: sdp })
		).then(function () {
			pc.createAnswer().then(function (description) {
				return pc.setLocalDescription(description).then(function () {
					if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Anfrage genehmigt";
					that.sendMessage(context, new SubscribeSdpResponseMessage("", String(description.sdp), txRtpEndpointId, stream, stream.name)
					);
					if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Technischer Test erfolgreich";
					if (context.testRunning !== null) context.testRunning = false;
				});
			});
		});
	},
	handleStreamStatusChange(
		context: any,
		stream: StreamObject,
		active: boolean,
		member: MemberObject
	) {
		// the status of a stream changed
		if (active) {
			this.addStreamVideoElement(context, stream, member);
			if (context.testStep !== null && context.testStep !== undefined) context.testStep += "<br>Stelle Anfrage, eigenen Stream zu erhalten";
			this.sendMessage(
				context,
				new SubscribeStreamMessage("", stream.name, stream, true, true)
			);
			// remove stream.name as it is part of stream (redundant)
		} else {
			// Die folgende Methode fehlte und war hier als 'to do' markiert.
			// Ich kann nicht sagen ob die wirklich notwendig ist. - K
			this.removeStreamVideoElement(context, stream, member, active);
		}
	},
	addStreamVideoElement(
		context: any,
		stream: StreamObject,
		member: MemberObject
	) {
		let counter: number = 0
		for (const streamObject of context.otherStreams) {
			if (streamObject.stream === null || streamObject.name === member.memberUuid) {
				streamObject.stream = stream;
				streamObject.name = member.memberUuid;
				if (context.hasOwnProperty('patientUsers')) {
					context.patientUsers[counter] = member.displayName;
				}
				if (context.hasOwnProperty('patientIds')) {
					context.patientIds[counter] = member.memberUuid;
				}
				break;
			}
			counter++;
		}
	},
	removeStreamVideoElement(
		context: any,
		stream: StreamObject,
		member: MemberObject,
		active: boolean
	) {
		console.log("RemoveStreamVideoElement Method called for memeber: " + member.displayName);
		if (context.hasOwnProperty('otherStreams')) {
			for (const streamObject of context.otherStreams) {
				if (streamObject.stream === null || streamObject.name === member.memberUuid) {
					console.log("Found corresponding Stream for Member with UUID: " + member.memberUuid);
					var localStream = streamObject.stream;
					localStream.audio = stream.audio;
					localStream.video = stream.video;
					localStream.active = active;
					if (!active) {
						if (localStream.pc) {
							try {
								console.log("Attempting to close the peer connection of this Stream...")
								localStream.pc.close();
								localStream.pc = null;
							} catch (error) {
								console.log("something went wrong closing the Stream.")
							}
						}
						delete context.otherStreams[stream.name];
					}
				}
			}
		}
	},
	findStreamByUuid(uuid: string, context: any) {
		if (context.hasOwnProperty('otherStreams')) {
			for (const stream of context.otherStreams) {
				if (stream.name === uuid) {
					return stream;
				}
			}
		}
		return null;
	},
	sendPing(context: any) {
		this.sendMessage(context, new PingMessage(context.$store.state.authentication.token));
	},
	sendMute(context: any, mute: boolean, excludeMemberUuid: string) {
		this.sendMessage(context, new MuteMessage("", mute, excludeMemberUuid));
	},
	sendUserMuted(context: any, mute: boolean) {
		this.sendMessage(context, new UserMutedMessage("", mute));
	},
	transmitMeldung(context: any, status: boolean) {
		this.sendMessage(context, new MeldungMessage("", status))
	},
	endMeldungForPatient(context: any, memberUuid: string) {
		this.sendMessage(context, new EndMeldungByTherapeutMessage("", memberUuid));
	}
};
